import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";

import $ from 'jquery';

// import required modules
import { EffectCreative, Navigation } from "swiper";


const bookslider = () => {

  return (

    <Swiper
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      modules={[EffectCreative, Navigation]}
      navigation
      className="bookslider-home-wrapper"
      style={{overflow: "visible"}}
    >

      <SwiperSlide style={{background: "#fff"}}>

      <center>

        <a href="/decor-book-5" target="_blank">
          
        {/* <input className="swiperbookinp" value="View" type="button"></input> */}

        <img className="bookslider-home bs-24" src="/images/2024/main-1.webp" />

        </a>

      </center>

      </SwiperSlide>
      
      <SwiperSlide style={{background: "#fff"}}>

        <center>
       
          <a href="/decor-books" target="_blank">
            
          {/* <input className="swiperbookinp" value="View" type="button"></input> */}

          <img className="bookslider-home" src="/images/1a.webp" />
        
          </a>

        </center>

      </SwiperSlide>

      
      <SwiperSlide style={{background: "#fff"}}>

        <center>
        
          <a href="/decor-book-2" target="_blank">

          {/* <input className="swiperbookinp" value="View" type="button"></input>{" "} */}

          <img className="bookslider-home" src="/images/2a.webp" />
          
          </a>

        </center>

      </SwiperSlide>

      <SwiperSlide style={{background: "#fff"}}>

        <center>

          <a href="/decor-book-3" target="_blank">
        
          {/* <input className="swiperbookinp" value="View" type="button" ></input>{" "} */}

          <img className="bookslider-home" src="/images/3a.webp" />
          
          </a>

        </center>
      </SwiperSlide>

    </Swiper>

  );
};

export default bookslider;
